import './dot.css'

export default function Dot() {
  return (
    <div>
        <div className="round"></div>
        <div className="round"></div>
        <div className="round"></div>
        <div className="round"></div>
    </div>
  )
}
